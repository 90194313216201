import initYoutubePlayer from './youtube-video';

const lectionsSlides = document.querySelectorAll('.lections-popup__image-slide');
let loaded = false;

const loadLectionsVideos = () => {
	const iframes = document.querySelectorAll('.lections-popup .youtube-video--iframe');

	for (let i = 0; i < iframes.length; i++) {
		const iframesItem = iframes[i];

		iframesItem.id = iframesItem.dataset.id;
	}
};

document.addEventListener('DOMContentLoaded', () => {
	const { pageYOffset } = window;
	const previousSection = document.querySelector('.instagram');
	const previousSectionOffset = previousSection.getBoundingClientRect().top + pageYOffset;

	const lazyLoadVideos = () => {
		const scrolled = window.pageYOffset || document.documentElement.scrollTop;

		if (loaded) {
			return false;
		}

		if (scrolled > previousSectionOffset) {
			loadLectionsVideos();

			for (let i = 0; i < lectionsSlides.length; i++) {
				initYoutubePlayer(lectionsSlides[i]);
			}

			loaded = true;
		}
	};

	lazyLoadVideos();
	document.addEventListener('scroll', lazyLoadVideos);
	window.addEventListener('resize', lazyLoadVideos);
	window.addEventListener('orientationchange', lazyLoadVideos);
});
