import CONSTANTS from '../common/constants';

const {
	event,
	canChangeUrl,
	locale,
	widgets: { speakers },
	width,
	fixedHeader,
	hideAds,
} = CONSTANTS.WIDGET;

function generateUID() {
	return (
		((Math.random() * 46656) | 0).toString(36).slice(-3) +
		((Math.random() * 46656) | 0).toString(36).slice(-3)
	);
}

const container = document.querySelector('.container');

const widgetElement = document.createElement('div');
widgetElement.classList.add('widget-external-list');
widgetElement.id = `widget-external-list-${generateUID()}`;
container.appendChild(widgetElement);

function handleSpeakerClick(evt) {
	evt.preventDefault();

	if (evt.target.classList.contains('speaker__video-button')) return false;

	let target = evt.currentTarget !== null ? evt.currentTarget : evt.target;

	const findSlide = node => {
		if (!node.dataset.widgetDetail) {
			target = findSlide(node.parentNode);
		} else {
			return node;
		}

		return target;
	};

	target = findSlide(target);

	const detail = JSON.parse(target.dataset.widgetDetail);

	widgetElement.dispatchEvent(new CustomEvent('EWidgets:openSpeakerDetail', { detail }));
	// widgetElement.allowTouchMove = false;
}

const speakerButtons = document.querySelectorAll('.speakers__item--popup');

for (let i = 0; i < speakerButtons.length; i++) {
	const speakerButton = speakerButtons[i];

	if (speakerButton.dataset.widgetDetail) {
		const detail = JSON.parse(speakerButton.dataset.widgetDetail);

		if (detail) {
			speakerButton.addEventListener('click', handleSpeakerClick, false);
		}
	}
}

const initWidgetSpeakersMain = () => {
	EWidgets(widgetElement.id, {
		event,
		canChangeUrl,
		locale,
		widgets: speakers,
		width,
		fixedHeader,
		hideAds,
	});
};

export { initWidgetSpeakersMain };
export default handleSpeakerClick;
