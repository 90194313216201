import Lottie from 'lottie-web';

const hare = document.querySelector('.first-screen__hare');
// const logo = document.querySelector('.first-screen__logo');

Lottie.loadAnimation({
	container: hare, // the dom element that will contain the animation
	renderer: 'svg',
	loop: true,
	autoplay: true,
	path: '/docs/zaec.json', // the path to the animation json
});

function setWidth(){
	const hareWidth = hare.offsetWidth;
	hare.style.height=`${hareWidth * 600 / 1100}px`;
}
setWidth();
window.addEventListener('resize', setWidth);

// Lottie.loadAnimation({
//     container: logo, // the dom element that will contain the animation
//     renderer: 'canvas',
//     loop: true,
//     autoplay: true,
//     path: '/docs/logo-404-desktop.json' // the path to the animation json
// });

// const header = document.querySelector('.first-screen');

// const lEye = header.querySelector('.hare__eye__border--left');
// const rEye = header.querySelector('.hare__eye__border--right');
// const lPupil = lEye.querySelector('.hare__eye__iris--left');
// const rPupil = rEye.querySelector('.hare__eye__iris--right');

// const drawEye = (eyecontainer, eyepupil, speed, interval) => {
//     let mouseX = 0;
//     let mouseY = 0;
//     let xp = 0;
//     let yp = 0;

//     const limitX = eyecontainer.offsetWidth - eyepupil.offsetWidth;
//     const limitY = eyecontainer.offsetHeight - eyepupil.offsetHeight;
//     const offset = eyecontainer.getBoundingClientRect();

//     const headerMousemoveHandler = (evt) => {
//         mouseX = Math.min(evt.pageX - offset.left, limitX);
//         mouseY = Math.min(evt.pageY - offset.top, limitY);

//         if (mouseX < 0) mouseX = 0;
//         if (mouseY < 0) mouseY = 0;

//         setInterval(() => {
//             xp += (mouseX - xp) / speed;
//             yp += (mouseY - yp) / speed;

//             const style = { left: `${xp}px`, top: `${yp}px` };

//             setTimeout(() => Object.assign(eyepupil.style, style), 0);
//         }, interval);
//     };

//     header.addEventListener('mousemove', headerMousemoveHandler);
// };

// // create eyes
// drawEye(lEye,  lPupil, 4, 30);
// drawEye(rEye, rPupil, 4, 30);
