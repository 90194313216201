function scrollAnchors(evt, respond = null) {
	evt.preventDefault();

	const distanceToTop = el => Math.floor(el.getBoundingClientRect().top);
	const targetID = respond ? respond.getAttribute('href') : this.getAttribute('href');
	const targetAnchor = document.querySelector(targetID);
	const offset =
		targetID === '#place' || targetID === '#themes' || targetID === '#speakers' ? 100 : 0;

	if (!targetAnchor) return;

	const originalTop = distanceToTop(targetAnchor) - offset;

	window.scrollBy({ top: originalTop, left: 0, behavior: 'smooth' });
	history.replaceState(null, null, ' ');
}

function scrollTo(links) {
	[...links].forEach(it => it.addEventListener('click', scrollAnchors));
}

export { scrollAnchors };
export default scrollTo;
