const htmlElement = document.querySelector('html');
const mobileMenuButtonElement = document.querySelector('.mobile-menu-button');
const mobileMenuElement = document.querySelector('.mobile-menu');

function handleMobileMenuButtonClick()
{
    if (mobileMenuButtonElement.classList.contains('mobile-menu-button--on'))
    {
        htmlElement.classList.remove('no-scroll');
        mobileMenuButtonElement.classList.remove('mobile-menu-button--on');
        mobileMenuElement.classList.remove('mobile-menu--open');
    }
    else
    {
        htmlElement.classList.add('no-scroll');
        mobileMenuButtonElement.classList.add('mobile-menu-button--on');
        mobileMenuElement.classList.add('mobile-menu--visible');
        mobileMenuElement.classList.add('mobile-menu--open');
    }
}

function handleMobileMenuTransitionEnd()
{
    if (!mobileMenuElement.classList.contains('mobile-menu--open'))
    {
        mobileMenuElement.classList.remove('mobile-menu--visible');
    }
}

if (mobileMenuButtonElement && mobileMenuElement)
{
    mobileMenuButtonElement.addEventListener('click', handleMobileMenuButtonClick, false);
    mobileMenuElement.addEventListener('transitionend', handleMobileMenuTransitionEnd, false);
}