import 'es6-promise/auto';
import axios from 'axios';
import Swiper from 'swiper/dist/js/swiper.min';
import View from '../common/View';

let container;
const desktopWidth = 280;
const mobileWidth = 196;

let currentSwiper = null;

const htmlElement = document.querySelector('html');

const insertSwiper = countOfPhotos => {
	currentSwiper = new Swiper(container, {
		loop: true,
		slidesPerView: countOfPhotos,
		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
		},

		on: {
			touchStart() {
				htmlElement.classList.add('mouse-down');
			},

			touchEnd() {
				htmlElement.classList.remove('mouse-down');
			},
		},
	});
};

const insertSlider = () => {
	const section = document.querySelector('.instagram');

	container = document.querySelector('.instagram__photo-container:not(.lazy)');

	if (!container || currentSwiper) {
		return false;
	}

	const images = section.querySelectorAll('img, source');

	for (let i = 0; i < images.length; i++) {
		const el = images[i];
		const { src, srcset } = el.dataset;

		if (src) {
			el.src = src;
		}

		if (srcset) {
			el.srcset = srcset;
		}
	}

	section.style.visibility = 'visible';
	const currentWidth = View.isPhone() ? mobileWidth : desktopWidth;
	const countOfPhotos = Math.ceil(document.documentElement.clientWidth / currentWidth);
	container.style.width = `${countOfPhotos * currentWidth}px`;
	container.style.marginLeft = `-${(countOfPhotos * currentWidth -
		document.documentElement.clientWidth) /
		2}px`;

	insertSwiper(countOfPhotos);
};

const resizeSwiper = () => {
	if (currentSwiper) {
		currentSwiper.destroy();
		currentSwiper = null;
	}

	insertSlider();
};

window.addEventListener('load', insertSlider);
window.addEventListener('scroll', resizeSwiper);
window.addEventListener('resize', resizeSwiper);

// const wrapper = document.querySelector('.instagram__photo-wrapper');

// axios
// 	.get('https://www.instagram.com/explore/tags/404fest/?__a=1')
// 	.then(response => {
// 		if (response.data && response.data.graphql && response.data.graphql.hashtag) {
// 			const posts = response.data.graphql.hashtag.edge_hashtag_to_top_posts.edges;

// 			for (let i = 0; i < posts.length; i++) {
// 				const div = document.createElement('div');
// 				div.style.padding = '12px';
// 				div.className = 'swiper-slide instagram__photo-slide';

// 				const a = document.createElement('a');
// 				a.href = `https://www.instagram.com/p/${posts[i].node.shortcode}/`;
// 				a.target = '_blank';

// 				const img = new Image();
// 				img.src = posts[i].node.thumbnail_src;

// 				a.appendChild(img);
// 				div.appendChild(a);

// 				wrapper.appendChild(div);
// 			}

// 			const swiper = new Swiper('.instagram__photo-container', {
// 				loop: true,
// 				slidesPerView: countOfPhotos,
// 				autoplay: {
// 					delay: 5000,
// 				},
// 			});
// 		}
// 	})
// 	.catch(() => errorHandling());
