const popup = document.querySelector('.entity-popup');
const popupClassNameHidden = 'entity-popup--hidden';
const entityBtn = document.querySelector('.how-much__button-entity');
const closeBtn = popup.querySelector('.entity-popup__close');

const entityBtnClickHandler = () => {
	popup.classList.toggle(popupClassNameHidden);
};

const hidePopup = (evt) => {
    const target = evt.target;

    if (target === closeBtn || target.tagName === "BUTTON" || target.classList.contains('entity-popup__wrapper')) {
        popup.classList.add(popupClassNameHidden);
    }
}

entityBtn.addEventListener('click', entityBtnClickHandler);
popup.addEventListener('click', hidePopup);
