class View {
	static isDesktop() {
		return window.matchMedia('(min-width: 1025px)').matches;
	}

	static isMobile() {
		return window.matchMedia('(max-width: 1024px)').matches;
	}

	static isPhone() {
		return window.matchMedia('(max-width: 767px)').matches;
	}

	static isTablet() {
		return window.matchMedia('(min-width: 768px) and (max-width: 1024px)').matches;
	}
}

export default View;
