import Swiper from 'swiper/dist/js/swiper.min';
import View from '../common/View';

const locationElement = document.querySelector('.location');

let isDesktop = !View.isDesktop();
let imagesHidden = !!document.querySelector('.location--images-hidden');

let screenCenterY = 0;
let locationElementCenterY = 0;

let direction = 0;
let { scrollY } = window;

function updateVerticalCenters() {
	screenCenterY = parseInt(window.innerHeight / 2, 10);

	locationElementCenterY = parseInt(
		locationElement.offsetTop + locationElement.offsetHeight / 2,
		10
	);
}

function handleScroll() {
	updateVerticalCenters();
	direction = window.scrollY - scrollY;
	scrollY = window.scrollY;

	if (direction < 0) {
		locationElement.classList.remove('location--scroll-down');
		locationElement.classList.add('location--scroll-up');
	} else {
		locationElement.classList.remove('location--scroll-up');
		locationElement.classList.add('location--scroll-down');
	}

	const distance = Math.abs(parseInt(locationElementCenterY - screenCenterY - window.scrollY, 10));

	if (distance >= screenCenterY * 0.6) {
		if (!imagesHidden) {
			imagesHidden = true;
			locationElement.classList.add('location--images-hidden');
		}
	} else if (imagesHidden) {
		imagesHidden = false;
		locationElement.classList.remove('location--images-hidden');
	}
}

const swiperContainer = document.querySelector('.location__container-image');
const swiperWrapper = swiperContainer.querySelector('.location__image-wrapper');

let currentSwiper = null;

const insertSwiper = countOfPhotos => {
	currentSwiper = new Swiper('.location__container-image', {
		loop: true,
		slidesPerView: countOfPhotos,

		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
		},
	});
};

const insertSlider = () => {
	const countOfPhotos = Math.ceil(document.documentElement.clientWidth / 213);

	swiperContainer.style.width = `${countOfPhotos * 213}px`;
	swiperContainer.style.marginLeft = `-${(countOfPhotos * 213 -
		document.documentElement.clientWidth) /
		2}px`;

	insertSwiper(countOfPhotos);
};

function handleResize() {
	if (View.isPhone()) {
		const mobileImages = document.querySelectorAll('.location__image-mobile');

		for (let i = 0; i < mobileImages.length; i++) {
			const el = mobileImages[i];

			el.style.display = 'block';
		}

		return;
	}

	const images = locationElement.querySelectorAll('.location__image source, .location__image img');

	for (let i = 0; i < images.length; i++) {
		const el = images[i];
		const { src, srcset } = el.dataset;

		if (src) {
			el.src = src;
		}

		if (srcset) {
			el.srcset = srcset;
		}
	}

	if (View.isDesktop() && !isDesktop) {
		if (currentSwiper) {
			currentSwiper.destroy();
			currentSwiper = null;

			if (imagesHidden) {
				locationElement.classList.add('location--images-hidden');
			}

			swiperContainer.classList.remove('swiper-container');
			swiperWrapper.classList.remove('swiper-wrapper');
		}

		isDesktop = true;
		window.addEventListener('scroll', handleScroll, false);
		handleScroll();
	}

	if (!View.isDesktop() && isDesktop) {
		isDesktop = false;
		window.removeEventListener('scroll', handleScroll, false);

		locationElement.classList.remove('location--images-hidden');
		swiperContainer.classList.add('swiper-container');
		swiperWrapper.classList.add('swiper-wrapper');

		setTimeout(function() {
			insertSlider();
		}, 0);
	}

	if (isDesktop) {
		updateVerticalCenters();
	}
}

window.addEventListener('resize', handleResize, false);

updateVerticalCenters();
handleResize();
