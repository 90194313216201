import axios from 'axios';

import { hidePopup } from '../main/subscribe-popup';

const submitForm = document.querySelector('.form');
const inputValue = submitForm.querySelector('.form__input');
const formWrapper = submitForm.querySelector('.form__input-wrapper');

/**
 * Установка фокуса в инпут формы
 */
function setFocus() {
	document.getElementById('email').focus();
}

/**
 * Удаление класса ошибки с инпута
 */
function removeErrorClass() {
	formWrapper.classList.remove('form__input-error');
	formWrapper.classList.remove('form__input-error--server');
	formWrapper.classList.remove('form__input-error--email');
}

/**
 * Обработка ответа с сервера
 * @param {object} response ответ сервера
 */
function responseProcessing(response) {
	if (response.data.status === 'ok') {
		hidePopup();
		inputValue.value = '';
	} else {
		formWrapper.classList.add('form__input-error');

		if (response.data.message === 'email') formWrapper.classList.add('form__input-error--email');
		else formWrapper.classList.add('form__input-error--server');
	}
}

/**
 * Обработка ошибки
 */
function errorHandling() {
	formWrapper.classList.add('form__input-error');
	formWrapper.classList.add('form__input-error--server');
}

/**
 * Отправка данных на сервер
 */
function postAjax() {
	axios
		.post('/subscribe.php', { send: true, email: inputValue.value })
		.then(response => responseProcessing(response))
		.catch(() => errorHandling());
}

window.addEventListener('load', setFocus);
inputValue.addEventListener('input', removeErrorClass);
submitForm.addEventListener('submit', evt => {
	evt.preventDefault();
	postAjax();
});
