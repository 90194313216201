import videoFile from '../../video/404-bg-video-30fps.mp4';
import View from './View';

const videoTag = document.querySelector('#video-bg');

/**
 * Задание новой ширины и высоты, если ширина окна браузера больше ширины
 * @param {Element} video элемент, к которому необходимо применить новые стили
 * @param {number} newHeight новая ширина
 * @param {number} marginTop отступ для центрирования
 */
function setForGreaterWidth(video, newHeight, marginTop) {
	video.style.width = '100%';
	video.style.top = '50%';
	video.style.marginLeft = '0';
	video.style.left = '0';
	video.style.height = `${String(newHeight)}px`;
	video.style.marginTop = `${String(marginTop)}px`;
}

/**
 * Задание новой ширины и высоты, если высота окна браузера больше ширины
 * @param {Element} video элемент, к которому необходимо применить новые стили
 * @param {number} newWidth новая ширина
 * @param {number} marginLeft отступ для центрирования
 */
function setForGreaterHight(video, newWidth, marginLeft) {
	video.style.height = '100%';
	video.style.left = '50%';
	video.style.marginTop = '0';
	video.style.top = '0';

	video.style.width = `${String(newWidth)}px`;
	video.style.marginLeft = `${String(marginLeft)}px`;
}

/**
 * Изменение размера видео, в зависимости от размера окна браузера
 */
function changeSizeVideo(video) {
	if (!video.querySelector('video')) {
		// eslint-disable-next-line no-use-before-define
		insertVideo();
	}

	if (video !== null) {
		if (document.documentElement.clientWidth < document.documentElement.clientHeight) {
			const newWidth = (document.documentElement.clientHeight / 1080) * 1920;
			const marginLeft = -newWidth / 2;
			setForGreaterHight(video, newWidth, marginLeft);
		} else {
			const newHeight = (document.documentElement.clientWidth / 1920) * 1080;
			const marginTop = -newHeight / 2;

			if (newHeight < document.documentElement.clientHeight) {
				// if (newHeight < 956) {
				const newWidth = (document.documentElement.clientHeight / 1080) * 1920;
				// const newWidth = (956 / 1080) * 1920;

				const marginLeft = -newWidth / 2;
				setForGreaterHight(video, newWidth, marginLeft);
			} else {
				setForGreaterWidth(video, newHeight, marginTop);
			}
		}
	}
}

/**
 * Добавление видео на страницу, если ширина окна браузера больше 1024px
 */
function insertVideo() {
	if (View.isDesktop()) {
		document.getElementById(
			'video-bg'
		).innerHTML = `<video width="100%" height="auto" preload="auto" autoplay loop muted class="video"><source src="${videoFile}" type="video/mp4"></source></video>`;

		changeSizeVideo(videoTag);
	}
}

// Для показа видео в шапке
window.addEventListener('load', insertVideo);
window.addEventListener('resize', () => {
	changeSizeVideo(videoTag);
});

export { changeSizeVideo, insertVideo };
