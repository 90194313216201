const topMenu = document.querySelector('.top-menu');

const heightToChangePosition = window.innerHeight - topMenu.offsetHeight;

function changeClassFix(height) {
	const scrolled = window.pageYOffset || document.documentElement.scrollTop;

	if (scrolled > height) {
		topMenu.classList.add('top-menu--fix');
	} else {
		topMenu.classList.remove('top-menu--fix');
	}
}

window.addEventListener('load', () => {
	changeClassFix(heightToChangePosition);
});
window.addEventListener('scroll', () => {
	changeClassFix(heightToChangePosition);
});
