const youtubeVideoElements = document.querySelectorAll('.youtube-video');

// eslint-disable-next-line func-names
const initYoutube = callback => {
	window.onYouTubeIframeAPIReady = () => {
		for (let i = 0; i < youtubeVideoElements.length; i++) {
			callback(youtubeVideoElements[i]);
		}
	};
};

if (youtubeVideoElements.length) {
	const tag = document.createElement('script');
	tag.id = 'youtube-video-script';
	tag.src = 'https://www.youtube.com/iframe_api';

	const firstScriptTag = document.getElementsByTagName('script')[0];
	firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}

export default initYoutube;
