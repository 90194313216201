const languageButtons = document.querySelectorAll('.js-language-button');

for (let i = 0; i < languageButtons.length; i++) {
	languageButtons[i].addEventListener(
		'click',

		function(event) {
			document.cookie = `page_language=${
				event.target.dataset.language
			}; path=/; expires=Thu, 01 Jan 9999 00:00:00 GMT`;
		},

		false
	);
}
