import initYoutube from './youtube-config';

window.players = { lections: [], whatIs: [] };

function initYoutubePlayer(youtubeVideoElement) {
	const iframeElement = youtubeVideoElement.querySelector('.youtube-video--iframe');

	if (!iframeElement.id || typeof YT === 'undefined') {
		return;
	}

	const player = new YT.Player(iframeElement.id, {
		videoId: iframeElement.id.replace('youtube-video-', ''),

		events: {
			onReady() {
				youtubeVideoElement.classList.add('youtube-video--ready');

				const { id } = youtubeVideoElement.firstChild;
				const clickElements =
					// eslint-disable-next-line no-use-before-define
					type === 'whatIs'
						? document.querySelectorAll(
								`.what-is-fest-404__youtube-video-thumbnail[data-video-id="${id}"], .what-is-fest-404__youtube-video-thumbnail[data-video-id="${id}"] .what-is-fest-404__video-play-button-background`
						  )
						: youtubeVideoElement.querySelectorAll(
								'.youtube-video--thumbnail, .youtube-video--play-button'
						  );

				for (let i = 0; i < clickElements.length; i++) {
					clickElements[i].addEventListener(
						'click',

						// eslint-disable-next-line func-names
						function() {
							player.playVideo();
							youtubeVideoElement.classList.add('youtube-video--playing');
						},

						false
					);
				}

				const elems = document.querySelectorAll(
					'.workshops-popup__overlay, .workshops-popup__button-close, .lections-popup__button-close'
				);

				for (let i = 0; i < elems.length; i++) {
					elems[i].addEventListener('click', () => {
						player.stopVideo();
					});
				}
			},

			enablejsapi: 1,
			origin: window.location.href,
		},
	});

	const { type } = iframeElement.dataset;

	if (type && type !== 'about') {
		window.players[type].push(player);
	}
}

document.addEventListener('DOMContentLoaded', () => {
	initYoutube(initYoutubePlayer);
});

export default initYoutubePlayer;
