import smoothscroll from 'smoothscroll-polyfill';
import View from '../common/View';
import scrollTo, { scrollAnchors } from '../common/smooth-scroll';

smoothscroll.polyfill();

const nav = document.querySelector('.anchors');

const getSectionPoints = id => {
	const section = document.getElementById(id);
	const { pageYOffset } = window;

	if (!section) {
		return false;
	}

	return section.getBoundingClientRect().top + pageYOffset;
};

const CLASS_NAMES = {
	HIDDEN: 'anchors--disabled',
	SHOWN: 'anchors--enabled',
};
let sectionsPoints;

const navToggle = (currentClass, newClass) => {
	if (nav.classList.contains(CLASS_NAMES[currentClass])) {
		nav.classList.remove(CLASS_NAMES[currentClass]);
		nav.classList.add(CLASS_NAMES[newClass]);
	}
};

const selectActiveNav = scrolled => {
	let activeSection;
	const activeLink = nav.querySelector('a.active');
	const offset = 200;

	// eslint-disable-next-line no-restricted-syntax
	for (const key in sectionsPoints) {
		if (scrolled + offset > sectionsPoints[key]) {
			activeSection = key;
		}
	}
	if (activeLink) {
		activeLink.classList.remove('active');
	}

	const activeLinkNode = nav.querySelector(`a[data-link="${activeSection}"]`);

	if (activeSection && activeLinkNode) {
		activeLinkNode.classList.add('active');
	}
};

// eslint-disable-next-line consistent-return
const initNav = () => {
	if (View.isMobile()) {
		return false;
	}

	scrollTo(nav.querySelectorAll('a'));

	const topBlock = document.querySelector('.first-screen');
	const heightToChangePosition = topBlock.offsetHeight;
	const scrolled = window.pageYOffset || document.documentElement.scrollTop;

	if (scrolled > heightToChangePosition) {
		sectionsPoints = {
			about: getSectionPoints('about'),
			themes: getSectionPoints('themes'),
			speakers: getSectionPoints('speakers'),
			tickets: getSectionPoints('tickets'),
			place: getSectionPoints('place'),
			sponsors: getSectionPoints('sponsors'),
		};

		navToggle('HIDDEN', 'SHOWN');
		selectActiveNav(scrolled);
	} else {
		navToggle('SHOWN', 'HIDDEN');
	}
};

initNav();
document.addEventListener('scroll', initNav);
window.addEventListener('resize', initNav);
window.addEventListener('orientationchange', initNav);
// document
// 	.querySelector('.first-screen__description-wrapper')
// 	.addEventListener('click', scrollAnchors);
