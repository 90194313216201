import CONSTANTS from '../common/constants';
import helpers from '../common/helpers';
import initYoutube from '../common/youtube-config';

const {
	WIDGET: {
		event,
		locale,
		widgets: { reports },
		width,
		fixedHeader,
		hideAds,
		canChangeUrl,
	},
	TRACKS,
} = CONSTANTS;

const popupLists = document.querySelectorAll('.workshops-popup');
const buttonOpenArr = Array.from(
	document.querySelectorAll(
		'.workshop__wrapper, .what-is-fest-404__youtube-video-thumbnail, .popup-open, .speaker__video-button'
	)
	// document.querySelectorAll('.what-is-fest-404__youtube-video-thumbnail')
);
const actionClassArray = ['workshops-popup__overlay', 'workshops-popup__button-close'];
const popupsArray = Array.from(document.querySelectorAll('.workshops-popup'));

const players = [];

function closePopup(popup) {
	popup.classList.add('workshops-popup--opacity');

	setTimeout(() => {
		popup.classList.add('workshops-popup--hide');
	}, 300);

	if (popup.dataset.videoId) {
		for (let j = 0; j < players.length; j++) {
			const player = players[j];

			if (`youtube-video-${player.videoId}` === popup.dataset.videoId) {
				player.player.stopVideo();
				break;
			}
		}
	}
}

function clickPopup(evt, popup) {
	const currentElement = evt.target;
	actionClassArray.forEach(item => {
		if (currentElement.classList.contains(item)) closePopup(popup);
	});
}

function setSrcToVideo() {
	const video = document.querySelector('#video-bg source');

	if (video && video.dataset && video.src === '') {
		const src = video.dataset.src;

		video.setAttribute('src', src);
	}
}

function openPopup(evt) {
	// Подключаем видео при открытии popup
	setSrcToVideo();

	const buttonElement = evt.currentTarget;

	for (let i = 0; i < popupLists.length; i++) {
		const currentPopup = popupLists[i];

		if (currentPopup.id === buttonElement.dataset.reportListId) {
			showPopup(currentPopup, evt.target.dataset.id);
			break;
		}
	}

	if (buttonElement.dataset.videoId) {
		for (let i = 0; i < popupLists.length; i++) {
			const popupElement = popupLists[i];

			if (buttonElement.dataset.videoId === popupElement.dataset.videoId) {
				showPopup(popupElement);

				for (let j = 0; j < players.length; j++) {
					const player = players[j];

					if (
						`youtube-video-${player.videoId}` === buttonElement.dataset.videoId &&
						player.player.getPlayerState() !== 1
					) {
						player.player.playVideo();
						break;
					}
				}

				break;
			}
		}
	}

	if (buttonElement.dataset.reportListId) {
		const tagName = buttonElement.dataset.reportListId;
		const popup = document.getElementById(tagName);

		if (popup) {
			const listElement = popup.querySelector('.report-list');
			const track = TRACKS[tagName];

			if (listElement && listElement.children.length === 0) {
				EWidgets(listElement.id, {
					event,
					track,
					locale,
					widgets: reports,
					width,
					fixedHeader,
					hideAds,
					canChangeUrl,
					onContentLoaded: content => {
						const { hideEmptySectionsTitle } = helpers;
						const title = document.querySelector(
							'.workshops-popup:not(.workshops-popup--hide) .workshops-popup__title-lectures'
						);

						hideEmptySectionsTitle(content.sessions, track, title);
					},
				});
			}
		}
	}
}

function showPopup(popup, isLections) {
	const content = popup.querySelector('.workshops-popup__content');
	const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
	const iframe = popup.querySelector('.youtube-video--iframe');

	if (iframe && !isLections) {
		iframe.id = iframe.dataset.id;

		if (iframe.dataset.id) {
			initYoutubePlayer(popup);
		}

		iframe.removeAttribute('data-id');
	}

	content.style.top = `${scrollTop + 100}px`;

	popup.classList.remove('workshops-popup--hide');

	setTimeout(() => {
		popup.classList.remove('workshops-popup--opacity');
	}, 0);
}

function initYoutubePlayer(youtubeVideoElement) {
	const iframeElement = youtubeVideoElement.querySelector('.youtube-video--iframe');
	const videoId = iframeElement.id.replace('youtube-video-', '');

	if (!iframeElement.id) {
		return;
	}

	const player = new YT.Player(iframeElement.id, {
		videoId,

		events: {
			onReady() {
				players.push({
					videoId,
					player,
				});

				player.playVideo();
			},
		},

		enablejsapi: 1,
		origin: window.location.href,
	});
}

initYoutube(initYoutubePlayer);

buttonOpenArr.forEach(butt => butt.addEventListener('click', openPopup, false));
popupsArray.forEach(currentPopup =>
	currentPopup.addEventListener(
		'click',
		evt => {
			clickPopup(evt, currentPopup);
		},
		false
	)
);
