import Swiper from 'swiper/dist/js/swiper.min';
import View from '../common/View';

const body = document.querySelector('body');
let currentSwiper = null;
const galleryPopup = document.querySelector('.gallery-popup');
const closeButton = galleryPopup.querySelector('.gallery-popup__button-close');
const smallPhotoArray = Array.from(document.querySelectorAll('.location__image'));

function closePopup() {
	body.classList.remove('body-overflow');
	galleryPopup.classList.add('gallery-popup--opacity');

	setTimeout(() => {
		galleryPopup.classList.add('gallery-popup--hide');
	}, 300);
}

function openPopup(index) {
	body.classList.add('body-overflow');
	galleryPopup.classList.remove('gallery-popup--hide');
	currentSwiper.update();
	currentSwiper.slideTo(index);

	setTimeout(() => {
		galleryPopup.classList.remove('gallery-popup--opacity');
	}, 100);
}

if (!View.isPhone()) {
	closeButton.addEventListener('click', closePopup);

	smallPhotoArray.forEach(photo =>
		photo.addEventListener('click', () => {
			openPopup(photo.id);
		})
	);

	const insertSwiper = () => {
		currentSwiper = new Swiper('.gallery-popup__container-image', {
			loop: true,
			slidesPerView: 1,

			centeredSlides: true,
			preloadImages: false,
			lazy: true,

			autoplay: {
				delay: 5000,
				disableOnInteraction: false,
			},

			pagination: {
				el: '.swiper-pagination',
				clickable: true,
			},

			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		});
	};

	window.addEventListener('load', insertSwiper);
}
