import View from './View';

const REPEAT_STEPS = 3;
const PHRASE_ENCRYPTOR = 'https://2018.404fest.ru';
const encryptor = require('simple-encryptor')(PHRASE_ENCRYPTOR);

function getRandomInMax(max) {
	return Math.floor(Math.random() * (max + 1));
}

function setCode(node, val) {
	const codCont = node.querySelector('.code');

	if (codCont) {
		codCont.innerHTML = val;
	}
}

function nextStep({ steps, isEggStep = false }) {
	const activeStep = steps.querySelector('.step.active');
	const isHalfFirstActive = activeStep && activeStep.classList.contains('half-first');
	const halfSecond = isHalfFirstActive ? steps.querySelector('.half-second') : null;

	if (activeStep) {
		activeStep.classList.remove('active');
		activeStep.classList.add('pass');
		setCode(activeStep, '');
	}

	const actives = steps.querySelectorAll(
		`.step${isEggStep ? '.egg' : ':not(.egg)'}:not(.pass):not(.half-second)`
	);

	if (actives.length) {
		const nextActive = isEggStep
			? actives[0]
			: isHalfFirstActive
			? halfSecond
			: actives[getRandomInMax(actives.length - 1)];
		const code = nextActive.getAttribute('data-code');

		if (code) {
			setCode(nextActive, encryptor.decrypt(code));
		}
		nextActive.classList.add('active');

		return { isCircle: false };
	}
	return { isCircle: true };
}

function resetSteps() {
	const steps = document.querySelectorAll('.step:not(.egg)');

	for (let i = 0; i < steps.length; i++) {
		steps[i].classList.remove('pass');
		steps[i].classList.remove('active');
	}
}

(() => {
	const rabbitSection = document.querySelector('.rabbit__preload');

	if (View.isPhone()) {
		rabbitSection.style.display = 'none';
		return false;
	}

	rabbitSection.classList.add(rabbitSection.dataset.className);

	const rabbitBlock = rabbitSection.querySelector('.easter-egg');
	const steps = rabbitBlock.querySelector('.steps');
	const resetButton = rabbitBlock.querySelector('.step-reset');

	let mainCount = 0;

	resetButton.addEventListener('click', () => {
		const isEggStep = mainCount >= REPEAT_STEPS;
		const { isCircle } = nextStep({ steps, isEggStep });

		if (isCircle) {
			resetSteps();
			++mainCount;

			let nextMastEgg = isEggStep;

			if (isEggStep) {
				mainCount = 0;
				// nextMastEgg = false;
			}

			nextStep({ steps, isEggStep: nextMastEgg });
		}
	});

	nextStep({ steps });
})();
