/* eslint-disable func-names */
import View from './View';
import helpers from './helpers';

require('intersection-observer');

const { lazyLoadPopups } = helpers;
const locationsPopups = document.querySelectorAll('.swiper-lazy-location');
const sectionsPopups = document.querySelectorAll('.swiper-lazy-sections');

document.querySelector('.location').addEventListener('click', () => {
	/**
	 * @param  {?boolean} true - prevent images on mobiles
	 */
	lazyLoadPopups(locationsPopups, true);
});

document
	.querySelector('.workshops')
	.addEventListener('click', () => lazyLoadPopups(sectionsPopups));

document.addEventListener('DOMContentLoaded', function lazyLoadImages() {
	let lazyImages;

	const preloadHeight = 300;
	let active = false;

	const lazyLoad = function() {
		if (View.isPhone()) {
			lazyImages = [].slice.call(
				document.querySelectorAll(
					'img.lazy:not(.lazy--no-mobile), source.lazy:not(.lazy--no-mobile)'
				)
			);
		} else {
			lazyImages = [].slice.call(document.querySelectorAll('.lazy:not(.lazy--no-tablet-desktop)'));
		}

		if (active === false) {
			active = true;

			setTimeout(function() {
				lazyImages.forEach(function(lazyImage) {
					if (
						lazyImage.getBoundingClientRect().top - preloadHeight <= window.innerHeight &&
						lazyImage.getBoundingClientRect().bottom + preloadHeight >= 0 &&
						getComputedStyle(lazyImage).display !== 'none'
					) {
						if (lazyImage.dataset.src) {
							lazyImage.src = lazyImage.dataset.src;
						}

						if (lazyImage.dataset.srcset) {
							lazyImage.srcset = lazyImage.dataset.srcset;
						}

						lazyImage.classList.remove('lazy');

						lazyImages = lazyImages.filter(function(image) {
							return image !== lazyImage;
						});

						if (lazyImages.length === 0) {
							document.removeEventListener('scroll', lazyLoad);
							window.removeEventListener('resize', lazyLoad);
							window.removeEventListener('orientationchange', lazyLoad);
						}
					}
				});

				active = false;
			}, 200);
		}
	};

	const lazyLoadReset = () => {
		active = false;

		lazyLoad();
	};

	lazyLoad();
	document.addEventListener('scroll', lazyLoad);
	window.addEventListener('resize', lazyLoadReset);
	window.addEventListener('orientationchange', lazyLoad);
});

document.addEventListener('DOMContentLoaded', function lazyLoadCssImages() {
	const lazyBackgrounds = [].slice.call(document.querySelectorAll('.lazy-hidden'));

	if ('IntersectionObserver' in window) {
		// eslint-disable-next-line no-undef
		const lazyBackgroundObserver = new IntersectionObserver(function(entries) {
			entries.forEach(function(entry) {
				if (entry.isIntersecting) {
					entry.target.classList.remove('lazy-hidden');
					lazyBackgroundObserver.unobserve(entry.target);
				}
			});
		});

		// eslint-disable-next-line func-names
		lazyBackgrounds.forEach(function(lazyBackground) {
			// polyfill
			lazyBackgroundObserver.POLL_INTERVAL = 100;
			lazyBackgroundObserver.observe(lazyBackground);
		});
	}
});
