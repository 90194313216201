const popup = document.querySelector('.subscribe-popup');
const popupClassNameHidden = 'subscribe-popup--hidden';
const entityBtns = document.querySelectorAll('.btn-form-popup');
const closeBtn = popup.querySelector('.subscribe-popup__close');

const entityBtnClickHandler = () => {
	popup.classList.toggle(popupClassNameHidden);
};

const hidePopup = () => {
	popup.classList.add(popupClassNameHidden);
};

const popupCloseHandler = evt => {
	const target = evt.target;

	if (
		target === closeBtn ||
		target.tagName === 'BUTTON' ||
		target.classList.contains('subscribe-popup__wrapper')
	) {
		hidePopup();
	}
};

popup.addEventListener('click', popupCloseHandler);

for (let i = 0; i < entityBtns.length; i++) {
	entityBtns[i].addEventListener('click', entityBtnClickHandler);
}

export { hidePopup };
