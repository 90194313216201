const checkbox = document.querySelector('.form__checkbox__input');

function switchDisable(checked) {
	const submitBtn = document.querySelector('.form__button');

	if (!checked) {
		submitBtn.setAttribute('disabled', 'disabled');
	} else {
		submitBtn.removeAttribute('disabled');
	}
}

function checkboxesChangeHandler(evt) {
	const { checked } = evt.target;

	switchDisable(checked);
}

switchDisable(checkbox.checked);

checkbox.addEventListener('change', checkboxesChangeHandler);
