function handleCloseEWidgetModals(mutations) {
	mutations.forEach((mutation) => {
		if (mutation.attributeName === 'class' && mutation.oldValue.includes('ReactModal__Body--open') && !mutation.target.classList.contains('ReactModal__Body--open')) {
			window.history.replaceState(null, null, window.location.pathname)
		}
	});
}

const closeEWidgetModalsObserver = new MutationObserver((mutations) => { handleCloseEWidgetModals(mutations) });

const target = document.getElementsByTagName('body')[0];
const config = { attributes: true, attributeOldValue: true };

closeEWidgetModalsObserver.observe(target, config);
