import Swiper from 'swiper/dist/js/swiper.min';
import View from '../common/View';
import helpers from '../common/helpers';

const htmlElement = document.documentElement;
const lectionsList = htmlElement.querySelector('.lections__videos');
const lectionsPopup = htmlElement.querySelector('.lections-popup');
const closeButton = lectionsPopup.querySelector('.lections-popup__button-close');

let currentSwiperLections = null;
let currentPopupSwiperLections = null;
let activeIndex;
let previousIndex;

const insertSwiperLections = slidesPerView => {
	currentSwiperLections = new Swiper(lectionsList, {
		loop: true,
		slidesPerView,
		shortSwipes: false,
		longSwipesRatio: 0.2,
		longSwipesMs: 100,
		on: {
			init: () => {
				lectionsList.classList.remove('lections__videos--hidden');

				if (View.isPhone()) {
					lectionsList.classList.add('lections__videos--init-offset');
				}
			},

			sliderMove: () => {
				if (View.isPhone()) {
					lectionsList.classList.remove('lections__videos--init-offset');
				}
			},

			touchStart: () => {
				htmlElement.classList.add('mouse-down');
			},

			touchEnd: () => {
				htmlElement.classList.remove('mouse-down');
			},

			click: evt => {
				// eslint-disable-next-line no-use-before-define
				lectionsListHandler(evt);
			},
		},
	});
};

const insertPopupSwiperLections = () => {
	currentPopupSwiperLections = new Swiper('.lections-popup__container-image', {
		loop: true,
		slidesPerView: 1,

		centeredSlides: true,
		preloadImages: false,
		lazy: true,
		shortSwipes: false,
		longSwipesRatio: 0.2,
		longSwipesMs: 100,

		pagination: {
			el: '.swiper-pagination',
			clickable: true,
		},

		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},

		on: {
			slideChange: () => {
				if (currentPopupSwiperLections && currentPopupSwiperLections.activeIndex) {
					activeIndex = currentPopupSwiperLections.activeIndex - 1;
					previousIndex = currentPopupSwiperLections.previousIndex - 1;

					helpers.previousPlayerControl(false, 'lections', previousIndex);
					helpers.currentPlayerControl(true, 'lections', activeIndex);
				}
			},
		},
	});
};

const insertSliderLections = () => {
	const sections = document.querySelector('.lections');
	const container = sections.querySelector('.lections__videos');
	const desktopWidth = 388;

	if (View.isMobile()) {
		return false;
	}

	sections.style.display = 'block';
	const thumbnails = container.querySelectorAll('.what-is-fest-404__video-thumbnail');

	for (let i = 0; i < thumbnails.length; i++) {
		const className = thumbnails[i].dataset.thumbnailClassName;

		thumbnails[i].classList.add(className);
	}

	const currentWidth = desktopWidth;
	const countOfPhotos = Math.ceil(document.documentElement.clientWidth / currentWidth);

	container.style.width = `${countOfPhotos * currentWidth}px`;
	container.style.marginLeft = `-${(countOfPhotos * currentWidth -
		document.documentElement.clientWidth) /
		2}px`;

	insertSwiperLections(countOfPhotos);
};

const resizeSwiper = () => {
	if (currentSwiperLections) currentSwiperLections.destroy();
	insertSliderLections();
};

function openPopup(index) {
	const slide = parseInt(index, 10);

	document.body.classList.add('body-overflow');
	lectionsPopup.classList.remove('lections-popup--hide');
	currentPopupSwiperLections.update();
	currentPopupSwiperLections.slideTo(slide);

	setTimeout(() => {
		lectionsPopup.classList.remove('lections-popup--opacity');

		const activeSlide = lectionsPopup.querySelector('.swiper-slide-active');
		const playBtn = activeSlide.querySelector('.youtube-video--play-button');

		playBtn.click();
	}, 100);
}

function closePopup() {
	helpers.currentPlayerControl(false, 'lections', activeIndex - 1);
	document.body.classList.remove('body-overflow');
	lectionsPopup.classList.add('lections-popup--opacity');

	setTimeout(() => {
		lectionsPopup.classList.add('lections-popup--hide');
	}, 300);
}

function lectionsListHandler(evt) {
	const { target } = evt;
	const { id } = target.dataset;

	if (target.classList && target.classList.contains('lections__video__thumbnail')) {
		evt.preventDefault();
		openPopup(id);
	}
}

window.addEventListener('load', insertSliderLections);
window.addEventListener('resize', resizeSwiper);
window.addEventListener('load', insertPopupSwiperLections);
closeButton.addEventListener('click', closePopup);
