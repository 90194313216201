// import Swiper from 'swiper/dist/js/swiper.min';
// import View from '../common/View';
// import helpers from '../common/helpers';

// const { players } = window;

// let currentSwiper = null;

// const insertSwiper = () => {
// 	currentSwiper = new Swiper('.what-is-fest-404__videos:not(.lections__videos)', {
// 		slidesPerView: 'auto',
// 		slidesPerGroup: 1,
// 		speed: 300,
// 		spaceBetween: 0,

// 		on: {
// 			init: () => {
// 				// ToDo переписать на функцию из helpers
// 				if (currentSwiper && currentSwiper.previousIndex) {
// 					helpers.previousPlayerControl(false, 'whatIs', previousIndex);
// 					helpers.currentPlayerControl(true, 'whatIs', activeIndex - 1);
// 				}
// 			},
// 		},
// 	});
// };

// const insertSlider = () => {
// 	if (View.isPhone()) {
// 		insertSwiper();
// 	}
// };

// const resizeSwiper = () => {
// 	if (currentSwiper) currentSwiper.destroy();
// 	insertSlider();
// };

// window.addEventListener('load', insertSlider);
// window.addEventListener('resize', resizeSwiper);
