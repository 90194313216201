import View from './View';

const getPlayerAction = (play, player) => {
	if (play) {
		player.playVideo();
	} else {
		player.pauseVideo();
	}
};

const getPlayer = (type, index) => window.players[type][index];

export default {
	hideEmptySectionsTitle: (arr, track, title) => {
		const reportsCount = arr.some(it => it.TagIds[0] === track);

		if (reportsCount) {
			title.style.display = 'block';
		}
	},

	currentPlayerControl: (play, type, index) => {
		const currentPlayer = getPlayer(type, index);

		if (
			currentPlayer &&
			typeof currentPlayer.getPlayerState === 'function' &&
			currentPlayer.getPlayerState() === 2
		) {
			getPlayerAction(play, currentPlayer);
		}
	},

	previousPlayerControl: (play, type, index) => {
		const previousPlayer = getPlayer(type, index);

		if (
			previousPlayer &&
			typeof previousPlayer.getPlayerState === 'function' &&
			previousPlayer.getPlayerState() === 1
		) {
			getPlayerAction(play, previousPlayer);
		}
	},

	getTimeToEvent(y, m, d, h = 0) {
		const diff =
			/**
			 * @param  {number} m-1 - in Date.UTC month should be previous
			 * @param  {number} h+4 - local time zone

			 */
			new Date(Date.UTC(y, m - 1, d, h + 4, 0, 0)).getTime() -
			new Date(new Date().toUTCString()).getTime();

		let days = (diff / (60 * 60 * 24 * 1000)) % 365;
		days = days < 0 ? -1 : days;

		return parseInt(days, 10);
	},

	declOfNum(number, titles = ['день', 'дня', 'дней']) {
		const cases = [2, 0, 1, 1, 1, 2];

		return titles[
			number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]
		];
	},

	lazyLoadPopups(popups, hideMobile = false) {
		if (hideMobile && View.isPhone()) {
			return;
		}

		for (let i = 0; i < popups.length; i++) {
			const imagesItem = popups[i];
			const { src, srcset } = imagesItem.dataset;

			if (src) {
				imagesItem.src = src;
			}

			if (srcset) {
				imagesItem.srcset = srcset;
			}
		}
	},
};
