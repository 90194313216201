import { initWidgetSpeakers } from './widget-init';
import { initWidgetSpeakersMain } from '../main/speakers';
import initWidgetOrganizers from '../inner/organizers';
// import { insertSlider, resizeSwiper } from '../main/banners';

const query = window.location.search;
let loaded = false;

const addWidgetScript = (src, callback) => {
	const scriptTag = document.createElement('script');

	scriptTag.setAttribute('src', src);

	if (callback) {
		scriptTag.onreadystatechange = callback;
		scriptTag.onload = callback;
	}

	document.head.appendChild(scriptTag);
};

const lazyLoadWidgetMain = () => {
	if (loaded) {
		return false;
	}

	const scrolled = window.pageYOffset || document.documentElement.scrollTop;

	if (scrolled > 0 || query !== '') {
		addWidgetScript('https://cdn-test.eventicious.com/widgets/0.6.0-30/EWidgets.js', () => {
			// initWidgetSpeakers();
			initWidgetSpeakersMain();
			initWidgetOrganizers();
			// insertSlider();

			// window.addEventListener('resize', resizeSwiper);
		});

		loaded = true;
	}
};

document.addEventListener('DOMContentLoaded', lazyLoadWidgetMain);
document.addEventListener('scroll', lazyLoadWidgetMain);
window.addEventListener('resize', lazyLoadWidgetMain);
window.addEventListener('orientationchange', lazyLoadWidgetMain);
