import View from '../common/View';

(function addGlowForNotMobileSpeakers() {
	const speakers = document.querySelectorAll('.no-glow');

	if (speakers.length && !View.isMobile()) {
		for (let i = 0; i < speakers.length; i++) {
			const NO_GLOW_CLASS_NAME = 'no-glow';
			const el = speakers[i];
			const elClassList = el.classList;

			if (elClassList.contains(NO_GLOW_CLASS_NAME)) {
				el.classList.remove(NO_GLOW_CLASS_NAME);
			}
		}
	}
})();

const glowElements = document.querySelectorAll('.glow');

let isDesktop = !View.isDesktop();
let screenCenterY = 0;

function updateVerticalCenters() {
	screenCenterY = parseInt(window.innerHeight / 2, 10);
}

function handleScroll() {
	for (let i = 0; i < glowElements.length; i++) {
		const glowElement = glowElements[i];

		let glowElementOffsetTop = 0;
		let element = glowElement;

		while (element && element.tagName !== 'BODY') {
			glowElementOffsetTop += element.offsetTop;
			element = element.parentNode;
		}

		const distance = Math.abs(parseInt(glowElementOffsetTop - screenCenterY - window.scrollY, 10));
		const ratio = (1 - distance / screenCenterY) * (Math.PI / 2);

		glowElement.style.opacity = ratio >= 0 ? Math.sin(ratio) : 0;
	}
}

function handleResize() {
	if (View.isDesktop() && !isDesktop) {
		isDesktop = true;
		window.addEventListener('scroll', handleScroll, false);
		handleScroll();
	}

	if (!View.isDesktop() && isDesktop) {
		isDesktop = false;
		window.removeEventListener('scroll', handleScroll, false);
	}

	if (isDesktop) {
		updateVerticalCenters();
	}
}

window.addEventListener('resize', handleResize, false);

updateVerticalCenters();
handleResize();
