export default {
	WIDGET: {
		event: '465bf50e-a29b-4913-9675-2ff8bcd81824',
		locale: window.eWidgetLocale,
		baseUrl: window.eWidgetBaseUrl,
		canChangeUrl: true,
		widgets: {
			reports: ['reports'],
			speakers: ['speakers'],
			advertisement: ['advertisement'],
			schedule: ['schedule'],
			partners: ['partners'],
		},
		width: 1180,
		fixedHeader: false,
		hideAds: true,
	},
	TRACKS: {
		'report-popup--bussines': 19812,
		'report-popup--design': 19809,
		'report-popup--frontend': 19810,
		'report-popup--backend': 19811,
		'report-popup--future-technologies': 19813,
		'report-popup--mobile': 19808,
		'report-popup--pr-marketing': 19815,
		'report-popup--product-management': 20093,
		'report-popup--project-management': 19807,
		'report-popup--qa': 19814,
		'report-popup--ux': 19806,
		'report-popup--open-platform': 19981,
	},
};
