const SCROLL_SENSITIVE = 5;

const mobileBuyButton = document.querySelector('.mobile-buy-button');
const mobileBuyButtonContainer = document.querySelector('.mobile-buy-button__container');

const mobileBuyButtonOffsetTop = mobileBuyButton.offsetTop;
const mobileBuyButtonOffsetBottom = mobileBuyButton.offsetTop + mobileBuyButton.offsetHeight;

function handleScroll() {
  let prevScroll = 0;
  let isScrollUp = false;

  return () => {
    const scrolled = window.pageYOffset || document.documentElement.scrollTop;
    const isScrolledUnderButtonTop = mobileBuyButtonOffsetTop < scrolled;
    const isScrolledUnderButtonBottom = mobileBuyButtonOffsetBottom  < scrolled;

    if (prevScroll - scrolled > SCROLL_SENSITIVE) {
      isScrollUp = true;
      prevScroll = scrolled;
    } else if (prevScroll - scrolled < - SCROLL_SENSITIVE){
      isScrollUp = false;
      prevScroll = scrolled;
    }     

    if (isScrolledUnderButtonBottom) {
      mobileBuyButtonContainer.classList.add('mobile-buy-button__container--fix');
      if (isScrollUp) {
        mobileBuyButtonContainer.classList.add('mobile-buy-button__container--fix--show');
      } else {
        mobileBuyButtonContainer.classList.remove('mobile-buy-button__container--fix--show');
      }
      if (isScrollUp && mobileBuyButtonOffsetBottom + SCROLL_SENSITIVE * 2 > scrolled) {
        mobileBuyButtonContainer.classList.remove('mobile-buy-button__container--fix--show');
      }
    } else {
      mobileBuyButtonContainer.classList.remove('mobile-buy-button__container--fix');
      mobileBuyButtonContainer.classList.remove('mobile-buy-button__container--fix--show');
    }
    
    // if (!isScrolledUnderButtonTop) {
    //   mobileBuyButtonContainer.classList.remove('mobile-buy-button__container--fix');
    //   mobileBuyButtonContainer.classList.remove('mobile-buy-button__container--fix--show');
    // }
  }
}

const changeMobileBuyButtonClassFix = handleScroll();

window.addEventListener('load', () => {
	changeMobileBuyButtonClassFix();
});
window.addEventListener('scroll', () => {
	changeMobileBuyButtonClassFix();
});
