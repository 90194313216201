import Swiper from 'swiper/dist/js/swiper.min';
import View from '../common/View';
import handleSpeakerClick from './speakers';

const container = document.querySelector('.banners__container');
// const paginationElement = document.querySelector('.banners__pagination');

let currentSwiper = null;

// const handlePaginationElementMutate = (mutationsList, observer) => {
// 	const tabElements = paginationElement.querySelectorAll('.swiper-pagination-bullet--banner');

// 	if (tabElements.length) {
// 		observer.disconnect();
// 		paginationElement.classList.add('banners__pagination--loaded');
// 	}
// };

// // eslint-disable-next-line no-undef
// const observer = new MutationObserver(handlePaginationElementMutate);

// observer.observe(paginationElement, { attributes: true, childList: true, subtree: true });

const insertSwiper = countOfPhotos => {
	currentSwiper = new Swiper('.banners__container', {
		loop: true,
		slidesPerView: countOfPhotos,
		loopAdditionalSlides: 3,

		centeredSlides: View.isPhone(),

		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
		},

		pagination: {
			el: '.swiper-pagination--banner',
			clickable: true,
			bulletClass: 'swiper-pagination-bullet--banner',
			bulletActiveClass: 'swiper-pagination-bullet--banner-active',
		},

		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},

		breakpoints: {
			767: {
				spaceBetween: 25,
			},
		},

		on: {
			click: evt => {
				handleSpeakerClick(evt);
			},

			init: () => {
				const banner = document.querySelector('.banners');
				let activeSlide;

				setTimeout(() => {
					activeSlide = banner.querySelector('.swiper-pagination-bullet--banner-active');

					currentSwiper.autoplay.stop();

					if (activeSlide) {
						activeSlide.classList.remove('swiper-pagination-bullet--banner-active');
					}

					banner.classList.add('banners--loaded');

					setTimeout(() => {
						if (activeSlide) {
							activeSlide.classList.add('swiper-pagination-bullet--banner-active');
						}

						currentSwiper.autoplay.start();
					}, 1);
				}, 1);
			},
		},
	});
};

const insertSlider = () => {
	const countOfPhotos = View.isPhone() ? Math.ceil(document.documentElement.clientWidth / 238) : 1;
	if (View.isPhone()) {
		container.style.width = `${countOfPhotos * 238}px`;
		container.style.marginLeft = `-${(countOfPhotos * 238 - document.documentElement.clientWidth) /
			2 +
			11}px`;
	}

	insertSwiper(countOfPhotos);
};

const resizeSwiper = () => {
	if (currentSwiper) {
		currentSwiper.destroy();
	}

	insertSlider();
};

export { insertSlider, resizeSwiper };

window.addEventListener('load', insertSlider);
window.addEventListener('resize', resizeSwiper);
