import './common/widget-main-lazy-load';
import './main/hare-animation';
import './main/banners';
import './common/lazy-load';
import './main/instagram';
import './main/glows';
import './main/location';
import './common/top-menu';
import './common/mobile-menu';
import './main/what-video';
import './main/lections';
import './common/video';
// import './common/scrollMenu';
// import './main/mobile-menu';
import './main/workshops';
import './main/speakers-more';
import './common/language-link';
import './main/gallery';
// import './common/open-popup';
import './main/entity-popup';
// import './main/promocode-popup';
// import './main/covid-popup';
import './common/calendar';
import './common/hair';
// import './main/counter';
import './main/anchor-main';
import './common/subscribeHandler';
// import './2020';
// import './main/mouse-wheel';
import './common/lections-lazy-load';
// import { insertVideo, changeSizeVideo } from './common/video';
import './main/mobile-buy-button';

// // Для показа видео в шапке
// window.addEventListener('load', insertVideo);

// window.addEventListener('resize', () => {
// 	changeSizeVideo(videoTag);
// });

// import axios from 'axios';

// const submitForm = document.querySelector('.form');
// const inputValue = submitForm.querySelector('.form__input');
// const doneBlock = document.querySelector('.done');
// const formWrapper = submitForm.querySelector('.form__input-wrapper');

// /**
//  * Установка фокуса в инпут формы
//  */
// function setFocus() {
// 	document.getElementById('email').focus();
// }

// /**
//  * Удаление класса ошибки с инпута
//  */
// function removeErrorClass() {
// 	formWrapper.classList.remove('form__input-error');
// 	formWrapper.classList.remove('form__input-error--server');
// 	formWrapper.classList.remove('form__input-error--email');
// }

// /**
//  * Обработка ответа с сервера
//  * @param {object} response ответ сервера
//  */
// function responseProcessing(response) {
// 	if (response.data.status === 'ok') {
// 		doneBlock.classList.remove('block-none');
// 		submitForm.classList.add('block-none');

// 		setTimeout(function() {
// 			doneBlock.classList.add('block-none');
// 			submitForm.classList.remove('block-none');
// 			inputValue.value = '';
// 		}, 7000);
// 	} else {
// 		formWrapper.classList.add('form__input-error');

// 		if (response.data.message === 'email') formWrapper.classList.add('form__input-error--email');
// 		else formWrapper.classList.add('form__input-error--server');
// 	}
// }

// /**
//  * Обработка ошибки
//  */
// function errorHandling() {
// 	formWrapper.classList.add('form__input-error');
// 	formWrapper.classList.add('form__input-error--server');
// }

// /**
//  * Отправка данных на сервер
//  */
// function postAjax() {
// 	axios
// 		.post('/subscribe.php', { send: true, email: inputValue.value })
// 		.then(response => responseProcessing(response))
// 		.catch(() => errorHandling());
// }

// window.addEventListener('load', setFocus);
// inputValue.addEventListener('input', removeErrorClass);
// // submitForm.addEventListener('submit', evt => {
// // 	evt.preventDefault();
// // 	postAjax();
// // });
// // Todo: server errors there, fix it
