// const elements = document.querySelectorAll(
// 	'.top-menu .buy-button button, .how-much__button, .entity-popup__btn button'
// );
const elements = document.querySelectorAll('.top-menu .buy-button button, .how-much__button');

if (elements.length) {
	for (let i = 0; i < elements.length; i++) {
		const comment = document.createComment(
			'RabbitEncryptor:U2FsdGVkX19lFXJvwM9D9MPCSUkh1fDG0Ek7Uy/G0QN93L3v/aGnDDpfxVDjQuJamP+sJe+aO3ReeZ7KdwAiXep5XHmBoW8nb5Syji5SXBNJQ3ZeLzV0Ky62UUdX+bC9KuRMfoFJmh7VsVDhWA91dvSVDA==\nRabbitPassword:404Fest'
		);

		elements[i].appendChild(comment);
	}
}
