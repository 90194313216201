const INIT_ROWS_COUNT = 4;
const ROWS_INCREMENT = 2;

const speakersContainer = document.querySelector('.speakers__items');
const speakers = Array.from(speakersContainer.querySelectorAll('.speakers__item'));
const moreButton = document.getElementById('speakers-more-button');

const columnCount = Math.floor(speakersContainer.offsetWidth / speakers[0].offsetWidth);

initSpeakersShow();
moreButton.onclick = showSpeakers();

// window.addEventListener('resize', () => {
// 	initSpeakersShow();
// });

function initSpeakersShow() {
	speakers.forEach((speaker, index) => {
		if (index >= columnCount * INIT_ROWS_COUNT) {
			speaker.classList.add('speakers__item--hidden');
		} else {
			speaker.classList.remove('speakers__item--hidden');
		}
	});
}

function showSpeakers() {
	let showFrom = INIT_ROWS_COUNT * columnCount;
	const speakersIncrement = columnCount > 2 ? columnCount * ROWS_INCREMENT : columnCount * ROWS_INCREMENT * 2;

	return () => {
		let showTo =
			showFrom + speakersIncrement >= speakers.length - 1
				? speakers.length
				: showFrom + speakersIncrement;

		for (let i = showFrom; i < showTo; i++) {
			speakers[i].classList.remove('speakers__item--hidden');
		}

		if (showTo < speakers.length) {
			showFrom = showTo;
		} else {
			moreButton.parentNode.style.display = 'none';
		}
	};
}
