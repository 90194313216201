import CONSTANTS from './constants';

const {
	event,
	locale,
	widgets: { speakers, schedule, reports, partners },
	width,
	fixedHeader,
	baseUrl,
	canChangeUrl,
	hideAds,
} = CONSTANTS.WIDGET;

const initWidgetOrganizers = () => {
	EWidgets('widget-organizers', {
		event: '6895058a-1f07-4c08-ac01-c28cf90aaeb8',
		baseUrl,
		locale,
		widgets: partners,
		width: 900,
		fixedHeader,
	});
};

const initWidgetReports = () => {
	EWidgets('widget-reports', {
		event,
		locale,
		widgets: reports,
		width,
		fixedHeader,
		hideAds,
		canChangeUrl,
	});
};

const initWidgetProgram = () => {
	EWidgets('widget-program', {
		event,
		baseUrl: window.eWidgetBaseUrl,
		locale,
		widgets: [...schedule],
		width,
		hideAds,
		dictionary: [
			{
				name: 'speakerReports',
				'ru-RU': 'Доклады',
				'en-US': 'Reports',
			},
			{
				name: 'scheduleDayMonthFormat',
				'ru-RU': 'MMMM',
				'en-US': 'MMMM',
			},
		],

		fixedHeader,
		canChangeUrl,
	});
};

const initWidgetSpeakers = () => {
	EWidgets('widget-speakers', {
		event,
		locale,
		widgets: speakers,
		widgetsSettings: [
			{
				name: 'speakers',
				speakersPerPage: -1,
			},
		],
		width,
		fixedHeader,
		hideAds,
		canChangeUrl,
	});
};

export { initWidgetSpeakers, initWidgetProgram, initWidgetReports, initWidgetOrganizers };
